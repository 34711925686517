import Layout from '@/framework/layout';
export default [
  {
    code: 'iov:truck',
    path: '/truck',
    name: 'truck',
    alwaysShow: true,
    component: Layout,
    redirect: 'enterpriseManager',
    meta: { title: '车辆', elSvgIcon: 'Fold' },
    children: [
      {
        code: 'iov:truckManage',
        path: '/truckManage',
        name: 'truckManage',
        component: () => import('@/framework/serviceView/truckManage/index.vue'),
        meta: { title: '车辆管理', cachePage: true }
      },
    ]
  }
];
