/**
 * v-watermark可接收参数，均为非必填
 * { text: 'vue-admin-box', font: '16px Microsoft JhengHei', textColor: '#000' }
 */
function addWaterMark(str, parentNode, font, textColor) {
    // 水印文字，父元素，字体，文字颜色
    var can = document.createElement('canvas');
    parentNode.appendChild(can);
    can.width = 200;
    can.height = 150;
    can.style.display = 'none';
    var cans = can.getContext('2d');
    cans.rotate((-20 * Math.PI) / 180);
    cans.font = font || '16px Microsoft JhengHei';
    cans.fillStyle = textColor || 'rgba(180, 180, 180, 0.3)';
    cans.textAlign = 'left';
    cans.textBaseline = 'middle';
    cans.fillText(str || 'sany-truck-iov-new-web', can.width / 10, can.height / 2);

    const waterMarkerEl = document.createElement("div")
    waterMarkerEl.id = "waterMarker"
    waterMarkerEl.style.position = 'absolute';
    waterMarkerEl.style.zIndex = 999;
    waterMarkerEl.style.pointerEvents = 'none';
    waterMarkerEl.style.backgroundImage = 'url(' + can.toDataURL('image/png') + ')'

    resizeWaterMarker(waterMarkerEl, parentNode)
    document.body.appendChild(waterMarkerEl)
    return waterMarkerEl
}

function resizeWaterMarker(waterMarkerEl, el) {
    waterMarkerEl.style.width = el.clientWidth + 'px';
    waterMarkerEl.style.height = el.clientHeight + 'px';
    waterMarkerEl.style.top = el.getBoundingClientRect().top + 'px';
    waterMarkerEl.style.left = el.getBoundingClientRect().left + 'px';
}

export default {
    mounted(el, binding) {
        binding.value ? binding.value : binding.value = {};
        let waterMarkerEl = document.querySelector("#waterMarker") || null
        waterMarkerEl && waterMarkerEl.parentNode.removeChild(waterMarkerEl);
        waterMarkerEl = addWaterMark(binding.value.text, el, binding.value.font, binding.value.textColor);
        window.removeEventListener('resize', resizeWaterMarker)
        window.addEventListener("resize", () => {
            resizeWaterMarker(waterMarkerEl, el)
        })
    },

    unmounted(el, binding) {
        // console.log(el)
        // window.removeEventListener('resize', resizeWaterMarker)
        // el && document.body.remove(el)
    },

    update(e, binding) {
        console.log(e)
    }
};


