<template>
  <div class="navbar rowBC reset-el-dropdown">
    <div class="rowBC">
      <!-- <hamburger :is-active="opened" class="hamburger-container" @toggle-click="toggleSideBar" /> -->
      <div class="title-logo">
        <div class="logo"></div>
        <div class="title">{{ setting.title }}</div>
      </div>
      <div class="menu-list">
        <el-menu :default-active="selectHeaderId" mode="horizontal" :ellipsis="false" :background-color="'transparent'"
          :text-color="'#141522'">
          <template v-for="(item) in userRoleMenus" :key="item.id">
            <el-menu-item v-if="item.isShow" :index="item.id" @click="handleSelect(item)">{{
                item.name
            }}</el-menu-item>
          </template>

        </el-menu>
      </div>
    </div>

    <div class="right-menu rowSC">
      <el-dropdown trigger="click" size="medium">
        <div class="rowSC">
          <div class="avatar-wrapper">
            <img :src="logo" class="user-avatar" />
          </div>
          <div style="margin-left:4px">
            <div class="text-left" style='color: #141522;font-size: 8px;font-weight:600;'>{{
                userInfo.orgName
            }}</div>
            <div class="text-left" style='color: #8E92BC;font-size: 7px;margin-top: 3px;'>{{
                userInfo.accountName
            }}</div>
          </div>
          <ArrowDown style="width: 12px; height: 12px; margin-left: 10px" />
          <!-- <CaretBottom style="width: 1em; height: 1em; margin-left: 4px" /> -->
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <router-link to="/">
              <el-dropdown-item>返回首页</el-dropdown-item>
            </router-link>
            <!--<el-dropdown-item>修改密码</el-dropdown-item>-->
            <el-dropdown-item divided @click="loginOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import setting from "@/framework/settings"
import { CaretBottom, ArrowDown } from '@element-plus/icons-vue';
import { watch } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import Hamburger from './Hamburger';
import logoIcon from '@/assets/layout/logo.png';
import DEFAULT_ICON from "./assets/avatar.png"
import { useAppStore } from '@/framework/store/app';
import { useUserStore } from '@/framework/store/user';
import { usePermissionStore } from "@/framework/store/permission"
import { isExternal } from "@/framework/utils/validate"

const userStore = useUserStore();
const permissionStore = usePermissionStore();
const userInfo = computed(() => userStore.userInfo);
const userRoleMenus = computed(() => userStore.roleMenus)
const opened = computed(() => appStore.sidebar.opened);
const selectHeaderId = computed(() => permissionStore.selectHeaderId);
const appStore = useAppStore();

const toggleSideBar = () => {
  appStore.M_toggleSideBar();
};
const state = reactive({
  // logo: logoIcon
  logo: DEFAULT_ICON
});
//export to page for
const { logo } = toRefs(state);

const router = useRouter();
const route = useRoute();

const handleSelect = (item) => {
  permissionStore.setSelectHeaderId(item.id)
  // TODO 点击头部菜单自动选中耳机菜单第一项（排除第一项为外链）
  let routePath = permissionStore.routes[0].path
  if (isExternal(routePath)) {
    return
  } else {
    router.push(routePath)
  }
}

const loginOut = () => {
  ElMessageBox.confirm('确定要退出登录吗?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    callback: (action) => {
      if (action === 'confirm') {
        userStore.logout().then(() => {
          ElMessage({ message: '退出登录成功', type: 'success' });
          localStorage.removeItem("tagViewStore")
          localStorage.removeItem("permission")
          localStorage.removeItem("user")
          router.push(`/login`);
        }).catch(() => {
          router.push(`/login`);
        });
      }
    }
  });
}
</script>

<style lang="scss" scoped>
.navbar {
  height: $navBarHeight;
  overflow: hidden;
  position: relative;
  background: $navBarBg;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .title-logo {
    display: flex;
    // background: #E5EEF7;
    align-items: center;
    line-height: $navBarHeight;
    padding: 0px 66px 0px 17px;
    background-image: url('./assets/bg.png');
    background-size: 100% 100%;
    // background-position: center;

    .logo {
      width: 18px;
      height: 18px;
      background-image: url('./assets/logo.png');
      background-size: 100% 100%;
      background-position: center;
    }

    .title {
      font-size: 16px;
      font-family: Plus Jakarta Sans-SemiBold, Plus Jakarta Sans;
      font-weight: 600;
      color: #141522;
      margin-left: 7px;
    }
  }

  .menu-list {
    display: flex;

    .el-menu-item {
      font-weight: 400;
      font-size: 10px;
      font-family: PingFang HK-Regular, PingFang HK;
      color: #141522;
      min-width: 73px;

      &.is-active,
      &:hover {
        color: #141522 !important;
        background-color: transparent;
        font-weight: 600;
      }
    }
  }

  &:deep() {
    .el-menu--horizontal {
      border-bottom: none
    }

    .el-menu--horizontal>.el-menu-item {
      border-bottom: none
    }

    .el-menu--horizontal>.el-menu-item.is-active {
      background-image: url('./assets/menuActive.png');
      background-size: 100% 100%;
      background-position: center;
    }
  }
}

//logo
.avatar-wrapper {
  margin-left: 5px;
  position: relative;
  cursor: pointer;

  .user-avatar {
    cursor: pointer;
    width: 26px;
    height: 26px;
    border-radius: 13px;
  }

  .el-icon-caret-bottom {
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: 25px;
    font-size: 12px;
  }
}

//center-title
.heardCenterTitle {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 46%;
  font-weight: 600;
  font-size: 20px;
  transform: translate(-50%, -50%);
}

//drop-down
.right-menu {
  margin-right: 16px;
  border-radius: 21px 21px 21px 21px;
  // opacity: 1;
  border: 1px solid #F5F5F7;
  padding: 5.5px 8px;
  box-shadow: 0px 1px 1px 0px #ECECEC;
}
</style>
