import { defineStore } from 'pinia';
import router, { constantRoutes, dynamicRouterList } from '@/framework/router';
import _ from 'lodash';
import { useUserStore } from '@/framework/store/user';
import { isExternal } from "@/framework/utils/validate"
import Layout from '@/framework/layout';

const redirectRoter = { path: '/:pathMatch(.*)', redirect: '/', name: 'redirect', hidden: true };
/**
 * 权限铺平, 返回所有有权限的菜单一维数组
 * @param resources 有权限的菜单
 * @param flatResources 铺平的权限 
 */
const flatLocalRouter = (routerList, flatRouter) => {
  routerList.forEach((item) => {
    const copyItem = _.cloneDeep(item);
    delete copyItem.children;
    flatRouter.push(copyItem);
    if (item.children && item.children.length > 0) {
      flatLocalRouter(item.children, flatRouter);
    }
  });
};

/**
 * 将远程映射为本地路由以便于动态
 * @param {*} sourceRouter 后端获取的权限树
 * @param {*} flatRouter 本地的铺平后的路由
 * @returns 
 */
const tranformRouter = (sourceRouter, flatRouter) => {
  const userStore = useUserStore();
  const objectRouter = [];
  sourceRouter.forEach((item) => {
    const itemRouter = flatRouter.find((obj) => obj.code === item.code); // 参照权限表和本地理由拿到有权限的路由
    if (itemRouter) {
      if (item.children && item.children.length) {
        itemRouter.children = tranformRouter(item.children, flatRouter);
      }
      // itemRouter.meta.title = item.name;
      itemRouter.meta = {
        ...itemRouter.meta,
        title: item.name
      }
      const hideRouter = flatRouter.find((obj) => obj.parentCode === itemRouter.code);
      if (hideRouter) {
        if (itemRouter.children) {
          itemRouter.children.push(hideRouter);
        } else {
          itemRouter.children = [];
          itemRouter.children.push(hideRouter);
        }
      }
      objectRouter.push(itemRouter);
    }
  });
  return objectRouter;
};

export const usePermissionStore = defineStore('permission', {
  /***
   *类似于组件的 data数据的 ,用来存储全局状态的
   * 1、必须是箭头函数
   */
  state: () => {
    return {
      routes: [], //将过滤后的异步路由和静态路由集合
      remoteRoutes: [], //过滤后的异步路由
      remoteRouteLoad: false,
      selectHeaderId: null // 选中的一级模块
    };
  },

  // 开启持久化
  persist: {
    enabled: true, // 启用
    strategies: [
      // storage 可选localStorage或sessionStorage
      // paths 给指定数据持久化
      { key: 'permission', storage: localStorage, paths: ['remoteRoutes', 'selectHeaderId', 'routes'] }
    ]
  },

  /***
   *封装处理数据的函数（业务逻辑)：修改数据
   */
  actions: {
    // 选择头部菜单时修改侧边栏及路由菜单
    setSelectHeaderId(id) {
      this.$patch((state) => {
        state.selectHeaderId = id;
        const UserStore = useUserStore()
        let resultRoute = UserStore.roleMenus.find(item => { return item.id == id })
        state.routes = state.remoteRoutes.find(item => {
          return item.code == resultRoute.code
        })?.children || []
      })
    },
    setRoutes(routes) {
      this.$patch((state) => {
        state.remoteRoutes = routes; // 从后端获取到的路由信息
        // debugger

        // state.routes = constantRoutes.concat(routes); // 拼接初始路由和动态路由
      });
    },
    setLeftMenu(routes) {
      this.$patch((state) => {
        state.routes = constantRoutes.concat(routes); // 拼接初始路由和动态路由
      });
    },
    setRemoteRouteLoad(status) {
      this.$patch((state) => {
        state.remoteRouteLoad = status;
      });
    },
    /**
     * 将远程路由和本地路由合并后动态加载
     * @param {*} roles 未处理的权限树
     * @returns
     */
    generateRoutes(roles) {
      return new Promise(async (resolve) => {
        const flatRouter = [];
        // 铺平本地路由 返回铺平后的flatRouter
        flatLocalRouter(dynamicRouterList, flatRouter);

        // 传入远端权限和本地路由
        const leftMenuRouter = tranformRouter(roles, flatRouter);
        const remoteRoutes = [...leftMenuRouter, redirectRoter];
        this.setRoutes(remoteRoutes);
        this.$patch((state) => {
          state.routes = state.routes.length == 0 ? remoteRoutes[0].children : state.routes
        })
        remoteRoutes.forEach((route) => {
          router.addRoute(route);
        });
        function deepName(route) {
          if (route.children) {
            return deepName(route.children[0])
          } else {
            return route.name
          }
        }
        let indexPageName = deepName(remoteRoutes?.[0])
        // debugger
        router.addRoute({
          path: '/',
          component: Layout,
          redirect: indexPageName,
        });
        this.setRemoteRouteLoad(true);
        resolve(remoteRoutes);

        // this.setRoutes(dynamicRouterList);
        // dynamicRouterList.forEach((route) => {
        //   router.addRoute(route);
        // });
        // this.setRemoteRouteLoad(true);
        // resolve(dynamicRouterList);
      });
    }
  }
});
