import axios from 'axios';
import router from '@/framework/router';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import _ from "lodash";
import qs from 'qs';
import stringify from '@/framework/utils/stringify';
import { useUserStore } from '@/framework/store/user';

let reqConfig;
let loadingE;

const service = axios.create({
  baseURL: '/', // url = base url + request url
  timeout: 5000, // request timeout
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: (params) => stringify(params),
  validateStatus: (status) => status >= 200 && status <= 500
});

// 请求拦截
service.interceptors.request.use(
  (request) => {
    // console.log('请求: ', request);
    const { access_token } = useUserStore();
    if (access_token) {
      request.headers.Authorization = `Bearer ${access_token}`;
    }
    if (request.data?.__accountId) {
      request.headers.account_id = request.data.__accountId;
    }
    // 给请求添加时间戳
    if (request.isParams) {
      request.params = request.data;
      request.data = {};
    } else {
      request.params = {};
    }
    _.forIn(request.params, function (value, key) {
      if (_.isString(value)) {
        request.params[key] = value.trim();
      }
    });
    request.params = request.params || {};
    request.params.request_no = new Date().getTime();
    if (request.method === 'get') {
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      request.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      };
    }
    return request;
  },
  (err) => {
    Promise.reject(err);
  }
);
// 响应拦截
service.interceptors.response.use(
  (res) => {
    // console.log('响应: ', res);
    const { status, statusText, data } = res;
    if (status === 200) {
      return data;
    } else {
      const userStore = useUserStore();
      const { code, message } = res.data;
      switch (code) {
        case 101022:
          ElMessage({
            message: message || '登录出错了',
            type: 'error',
            duration: 2 * 1000
          });
          userStore.resetState().then(() => {
            router.push({ path: '/login' });
          });
          break;
        case 101012:
          ElMessage({
            message: message || '登录出错了',
            type: 'error',
            duration: 2 * 1000
          });
          break;
        case 19010:
          ElMessage({
            message: message || '大数据平台异常，请联系管理员',
            type: 'error',
            duration: 2 * 1000
          });
          break;
      }
      return Promise.reject(res.data);
    }
  },
  (err) => {
    /*http错误处理，处理跨域，404，401，500*/
    if (loadingE) loadingE.close();
    ElMessage({
      message: err,
      type: 'error',
      duration: 2 * 1000
    });
    //如果是跨域
    //Network Error,cross origin
    // debugger
    const errObj = {
      msg: err.toString(),
      reqUrl: reqConfig.baseURL + reqConfig.url,
      params: reqConfig.isParams ? reqConfig.params : reqConfig.data
    };
    return Promise.reject(JSON.stringify(errObj));
  }
);

export function axiosReq({
  url,
  data,
  method,
  isParams,
  bfLoading,
  afHLoading,
  isUploadFile,
  isDownLoadFile,
  baseURL,
  timeout,
  isAlertErrorMsg = true,
  cancelToken,
  headers
}) {
  if (headers) {
    service.interceptors.request.use((request) => {
      Object.keys(headers).map(key => {
        request.headers[key] = headers[key];
      })
      return request
    })
  }
  return service({
    url: url,
    method: method ?? 'get',
    data: data ?? {},
    isParams: isParams ?? false,
    bfLoading: bfLoading ?? false,
    afHLoading: afHLoading ?? true,
    isUploadFile: isUploadFile ?? false,
    isDownLoadFile: isDownLoadFile ?? false,
    isAlertErrorMsg: isAlertErrorMsg,
    baseURL: baseURL ?? import.meta.env.VITE_APP_BASE_URL,
    timeout: timeout ?? 15000,
    cancleToken: cancelToken
  });
}

export default axiosReq;
