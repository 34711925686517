import axiosReq from "../utils/axiosReq";

const serverPrefix = import.meta.env.VITE_APP_NODE_ENV == 'development' ? "/OpenApiPrefix/api" : '/api';

// 通过用户ID查询车队列表
export function selectFleetManageInfo(userId) {
    return axiosReq({
      url: `${serverPrefix}/board/v1/mgmt/fleet-manage/selectFleetManageInfo/${userId}`,
      method: 'get',
      isParams: true,
    });
  }

  //通过用户Id查询车队管理员列表
  export function  selectFleetManageUserList(userId) {
    return axiosReq({
      url: `${serverPrefix}/board/v1/mgmt/fleet-manage/selectFleetManageUserList/${userId}`,
      method: 'get',
      isParams: true,
    });
  }

  //通过用户ID查询车辆列表数据
  export function selectFleetTruckInfo(userId){
    return axiosReq({
        url: `${serverPrefix}/board/v1/mgmt/fleet-manage/selectFleetTruckInfo/${userId}`,
        method: 'get',
        isParams: true,
      });
  }
