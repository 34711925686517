/*
 * @Author: 谢卓勋 xiezx7@sany.com.cn
 * @Date: 2022-06-20 10:01:24
 * @LastEditors: 谢卓勋 xiezx7@sany.com.cn
 * @LastEditTime: 2022-06-20 17:39:09
 * @Description: 
 */
import settings from "@/framework/settings"
const title = settings.title;

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle}-${title}`;
  }
  return `${title}`;
}
