/**
 * 过滤掉对象的循环引用问题
 */
export function circularJsonToString(jsonObj){
    var cache = [];
    var circularJsonStr = JSON.stringify(jsonObj, function(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
                return;
            }
            cache.push(value);
        }
        return value;
    });
    cache = null;
    return circularJsonStr;
}

