import Layout from '@/framework/layout';
export default [
  {
    code: 'iov:fleet',
    path: '/fleet',
    name: 'fleet',
    alwaysShow: true,
    component: Layout,
    redirect: 'fleetList',
    meta: { title: '车队孪生', elSvgIcon: 'Fold' },
    children: [
      // {
      //   code: 'iov:fleet:fleetIndex',
      //   path: '/fleetIndex',
      //   name: 'fleetIndex',
      //   component: () => import('@/framework/serviceView/fleet/index.vue'),
      //   meta: { title: '车队孪生首页', cachePage: true }
      // },
      // {
      //   code: 'iov:fleet:fleetOperation',
      //   path: '/extend/fleetOperation',
      //   name: 'fleetOperation',
      //   meta: { title: '车队运营', cachePage: true }
      // },
      /**
       * 车队管理的路由
       *  */
      //车队列表
      {
        code: 'iov:fleet:fleetList',
        path: '/fleetList',
        name: 'fleetList',
        component: () => import('@/framework/serviceView/fleet/fleetManager/teamList/fleetList.vue'),
        meta: { title: '车队列表', cachePage: true }
      },
      {
        code: 'iov:fleet:fleetOperation',
        path: '/fleetOperation',
        name: 'fleetOperation',
        component: () => import('@/framework/serviceView/fleet/fleetOperation/index.vue'),
        meta: { title: '车队运营', cachePage: true }
      },
      //车队能耗
      {
        code: 'iov:fleet:fleetEnergy',
        path: '/fleetEnergy',
        name: 'fleetEnergy',
        component: () => import('@/framework/serviceView/fleet/fleetManager/energy/fleetEnergy.vue'),
        meta: { title: '车队能耗', cachePage: true }
      },
      //能耗详情
      {
        code: 'iov:fleet:energyDetail',
        path: '/fleetEnergyDetail',
        name: 'fleetEnergyDetail',
        // component: () => import('@/framework/serviceView/truckTwin/energyAnalysis/index.vue'),
        component: () => import('@/framework/serviceView/fleet/fleetManager/energy/fleetEnergyDetail.vue'),
        hidden: true,
        meta: { title: '能耗详情', cachePage: true }
      },
      //车队行程
      {
        code: 'iov:fleet:fleetTrip',
        path: '/fleetTrip',
        name: 'fleetTrip',
        component: () => import('@/framework/serviceView/fleet/fleetManager/trip/fleetTrip.vue'),
        // component: () => import('@/framework/serviceView/fleet/fleetManager/trip/fleetTripMap.vue'),
        meta: { title: '行程轨迹', cachePage: true }
      },
      //车队行程地图
      {
        code: 'iov:fleet:fleetTripMap',
        path: '/fleetTripMap',
        name: 'fleetTripMap',
        // component: () => import('@/framework/serviceView/truckTwin/truckTrip/truckTripDetail.vue'),
        component: () => import('@/framework/serviceView/fleet/fleetManager/trip/fleetTripDetail.vue'),
        hidden: true,
        meta: { title: '行程轨迹地图', cachePage: true }
      },
      //车队管理
      {
        code: 'iov:fleet:my',
        path: '/fleetMy',
        name: 'myFleet',
        // component: () => import('@/framework/serviceView/fleet/myfleet/team/myFleetList.vue'),
        meta: { title: '车队管理', cachePage: true },

        children: [
          //我的车队
          {
            code: 'iov:fleet:my:fleetList',
            path: '/fleetMyList',
            name: 'fleetMyList',
            component: () => import('@/framework/serviceView/fleet/myfleet/team/myFleetList.vue'),
            meta: { title: '我的车队', cachePage: true }
          },
          //我的车队的司机
          {
            code: 'iov:fleet:my:driverList',
            path: '/fleetMyDriverList',
            name: 'fleetMyDriverList',
            component: () => import('@/framework/serviceView/fleet/myfleet/driver/myDriverList.vue'),
            meta: { title: '车队司机', cachePage: true }
          },
          //我的车队的车辆
          {
            code: 'iov:fleet:my:truckList',
            path: '/fleetMyTruckList',
            name: 'fleetMyTruckList',
            component: () => import('@/framework/serviceView/fleet/myfleet/truck/myTruckList.vue'),
            // component: () => import('@/framework/serviceView/fleet/myfleet/truck/truckListPage.vue'),
            meta: { title: '车队车辆', cachePage: true }
          },
        ]
      },
    ]
  }
];
