import axiosReq from "../utils/axiosReq";

/*
 * @Author: 谢卓勋 xiezx7@sany.com.cn
 * @Date: 2022-06-20 10:01:23
 * @LastEditors: 谢卓勋 xiezx7@sany.com.cn
 * @LastEditTime: 2022-07-15 17:49:16
 * @Description: 登录相关的接口
 */
const baseApi = import.meta.env.VITE_APP_NODE_ENV == 'development' ? "/OpenApiPrefix/api" : '/api';
// const baseApi=import.meta.env.VITE_APP_NODE_ENV == 'development' ?  "/OpenApiPrefix" : '/api';
const mgmtPrefix = `${baseApi}/board/v1/mgmt`;
//
const fleetManagerPrefix = `${mgmtPrefix}/fleet-manage`;//车队管理
const fuelPrefix = `${mgmtPrefix}/iot-rda/oil-wear`;//油耗分析
const truckServicePrifix = `${baseApi}/sany/sany/area`;//获取省市区  /sany/sany/area/getAllProvince
// const truckServicePrifix=`/sany-truck-service/sany/area`;//获取省市区  /sany/sany/area/getAllProvince



//上传文件
export function uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file);
  //return this.axios.post("api/sany/sany/v1/cms/upload", formData);
  // https://mall.sanytruck.com/api/sany/sany/v1/cms/upload
  // url: `${serverPrefix}/api/sany/sany/v1/cms/upload`,
  //  url: `https://shop.sanytruck.com/api/sany/sany/v1/cms/upload`,
  return axiosReq({
    url: `https://mall.sanytruck.com/api/sany/sany/v1/cms/upload`,
    method: 'post',
    data: formData,
    isUploadFile: true,
  });
}


//获取所在省份
export function getProvincesReq() {
  return axiosReq({
    // url: `/sany-truck-service/sany/area/getAllProvince`,
    url: `${truckServicePrifix}/getAllProvince`,
    method: 'get'
  });
}


//获取所在城市
export function getCitysReq(code) {
  return axiosReq({
    // url: `/sany-truck-service/sany/area/getAllCity?code=` + code,
    url: `${truckServicePrifix}/getAllCity?code=` + code,
    method: 'get'
  });
}


//管理系统-数据分析-400油耗监控分析-获取上次保养日期,提交时间段，开始统计分析
export function getMaintainDateStartAnalysisReq(data) {
  return axiosReq({
    // url: `/vd/sanymgmt/board/v1/mgmt/iot-rda/oil-wear/getMaintainDateStartAnalysis`,
    url: `${fuelPrefix}/getMaintainDateStartAnalysis`,
    method: 'get',
    isParams: true,
    data,
  });
}


//400油耗提交用户反馈信息并查询报告
export function saveFeedbackReq(data) {
  return axiosReq({
    // url: `/vd/sanymgmt/board/v1/mgmt/iot-rda/oil-wear/saveFeedback`,
    url: `${fuelPrefix}/saveFeedback`,
    method: 'post',
    data,
  });
}

//400油耗查询报告
export function getReportReq(data) {
  return axiosReq({
    // url: `/vd/sanymgmt/board/v1/mgmt/iot-rda/oil-wear/getReport`,
    url: `${fuelPrefix}/getReport`,
    method: 'get',
    isParams: true,
    data,
  });
}


/**
 * 
 * 
 * 
 * 以下是新的车队接口
 */

//  http://127.0.0.1:2180/board/v1/mgmt/fleet-manage/addFleetManageUser

//添加司机
export function addDriverHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/addFleetManageUser`,
    method: 'post',
    // isParams: true,
    data,
  });
}

//更新司机信息
export function updateDriverHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/updateFleetManageUser`,
    method: 'post',
    // isParams: true,
    data,
  });
}

//删除司机

//更新司机信息
export function deleteDriverHttpReq(id) {
  return axiosReq({
    url: `${fleetManagerPrefix}/deleteFleetManageUser/${id}`,
    method: 'get',
    // isParams: true,
    // data,
  });
}



//查询账号下的所有司机
export function getMyAllDriverHttpReq(accountId) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetManageUserList/${accountId}`,
    method: 'get',
    // isParams: true,
    // data,
  });
}

//查询账号下的所有司机
export function searchDriverListHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetManageUser`,
    method: 'post',
    // isParams: true,
    data,
  });
}


//根据车架号查询车牌
export function getPlateByVinHttpReq(vin) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectSingleFleetTruckInfo/${vin}`,
    method: 'get',
    // isParams: true,
    // data,
  });
}
// selectFleetManageTruck/LFXAH78W1M000000
// selectFleetManageUserList/1

//添加车队
export function addFleetHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/addFleetInfo`,
    method: 'post',
    // isParams: true,
    data,
  });
}


//编辑车队
export function updateFleetHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/updateFleetInfo`,
    method: 'post',
    // isParams: true,
    data,
  });
}



//selectFleetManageTruckList/4   selectFleetManageTruckList/4
//获取指定车队下的所有车辆
export function getFleetTrucksHttpReq(fleetId) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetManageTruckList/${fleetId}`,
    method: 'get',
    // isParams: true,
    // data,
  });
}

//查询车队的车辆列表
export function getFleetTruckListHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetTruckInfoList`,
    method: 'post',
    // isParams: true,
    data,
  });
}



//解绑车队的车辆
export function unbindFleetTruckHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/updateTruckStatus`,
    method: 'post',
    // isParams: true,
    data,
  });
}


//绑定车队的车辆
export function bindFleetTruckHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/addFleetManageTruck`,
    method: 'post',
    // isParams: true,
    data,
  });
}

//selectFleetInfoList

//获取指定账号下的车队列表(简单信息)
export function getMySimpleFleetListHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetInfoList`,
    method: 'post',
    // isParams: true,
    data,
  });
}

//获取指定账号下的车队列表（完整信息）
export function getMyFleetListHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetQueryListInfo`,
    method: 'post',
    // isParams: true,
    data,
  });
}


//获取指定账号下的车队列表(简单信息)
export function getMySimpleAllFleetListHttpReq(accountId) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetManageInfo/${accountId}`,
    method: 'get',
    // isParams: true,
    // data,
  });
}

//根据车队名称模糊查询车队列表(简单信息)
export function getSimpleAllFleetListHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectAllFleetInfoList`,
    method: 'post',
    // isParams: true,
    data,
  });
}


//删除车队
export function deleteFleetHttpReq(id) {
  return axiosReq({
    url: `${fleetManagerPrefix}/deleteFleetManage/${id}`,
    method: 'get',
    // isParams: true,
    // data,
  });
}

//车队添加车辆
export function addFleetTruckHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/addFleetManageTruck`,
    method: 'post',
    // isParams: true,
    data,
  });
}

//车队删除车辆
export function deleteFleetTruckHttpReq(id) {
  return axiosReq({
    url: `${fleetManagerPrefix}/deleteFleetManageTruck/${id}`,
    method: 'post',
    // isParams: true,
    // data,
  });
}

//车队批量导入车辆
export function importFleetTruckHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/addFleetTruckImport`,
    method: 'post',
    // isParams: true,
    data,
  });
}

//获取指定账号下的所有车辆列表
export function getMyTruckListHttpReq(accountId) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetManageTruckAsso/${accountId}`,
    method: 'get',
    // isParams: true,
    // data,
  });
}


//更新车辆信息
export function updateTruckInfoHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/updateFleetManageTruck`,
    method: 'post',
    // isParams: true,
    data,
  });
}


//查询车牌
export function queryPlateHttpReq(vin) {
  return axiosReq({
    url: `${fleetManagerPrefix}//selectSingleFleetTruckInfo/${vin}`,
    method: 'get',
  });
}


//获取车队能耗数据
export function getEnergyListHttpReq({ data, headers }) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetFuelListData`,
    method: 'post',
    // isParams: true,
    data,
    headers
  });
}

//获取车队列表数据
export function getFleetListHttpReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetListData`,
    method: 'post',
    // isParams: true,
    data,
  });
}


//获取车队统计数据
export function getFleetCountDataHttpReq() {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetTotal`,
    method: 'get',
    // isParams: true,
    // data,
  });
}

//获取车队详情
export function getFleetDetailHttpReq(fleetId) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetInfoDetail/${fleetId}`,
    method: 'get',
    // isParams: true,
    // data,
  });
}

//获取车队资产
export function getFleetBasicInfoByIdReq(fleetId) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetScreenBasicInfo/${fleetId}`,
    method: 'get',
    // isParams: true,
    // data,
  });
}

//获取车队画像
export function getFleetPortraitByIdReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetPortrait`,
    method: 'post',
    // isParams: true,
    data,
  });
}

//获取车队排名
export function getFleetRankByIdReq(data) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetRank`,
    method: 'post',
    // isParams: true,
    data,
  });
}

//获取车队车辆位置
export function getFleetTruckMapByIdReq(fleetId) {
  return axiosReq({
    url: `${fleetManagerPrefix}/selectFleetTruckMap/${fleetId}`,
    method: 'get',
    // isParams: true,
    // data,
  });
}

// http://localhost:5006/ApiPrefix/api/board/v1/mgmt/mileage-fuel/detail/list?searchType=DAILY&vin=LFXAH76W8N3080106&searchTimeBegin=2022-09-20&searchTimeEnd=2022-09-29&pageSize=1000&request_no=1664436852356
//里程油耗明细报表列表
export function getMileageFuelListReportHttpReq(data) {
  return axiosReq({
    url: `${mgmtPrefix}/mileage-fuel/detail/list`,
    method: 'get',
    isParams: true,
    data,
  });
}


//获取accountId
export function getAccountIdHttpReq(phone) {

  // /truck/{vinCode}
  var url = `${fleetManagerPrefix}/selectUserInfoSupplement/${phone}`;//TruckDetailReq
  return axiosReq({
    url: url,
    method: 'get',
  })
}

// 获取车辆类型
export function queryTruckTypeListReq(vinCode) {

  var url = `${baseApi}/board/v1/mgmt/dictionary/byName/TRUCK_TYPE`;
  return axiosReq({
    url: url,
    method: 'get',
    // data,
    // isParams: true,
  })
}