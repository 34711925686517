/**
 * 解析导入模块
 * @param moduleFiles require返回值
 * @param excludeFiles 需要排除的文件
 * @param prefix 需要删除的前缀
 * @param suffix 需要删除的后缀
 */
export function resolveRequire(moduleFiles, excludeFiles = ['index'], prefix = './', suffix = '.js') {
  const modules = {}
  Object.keys(moduleFiles).forEach((modulePath) => {
    if (excludeFiles) {
      const index = excludeFiles.findIndex((item) => `${prefix}${item}${suffix}` === modulePath)
      if (index !== -1) {
        return
      }
    }

    let moduleName = modulePath
    // 删除前缀和后缀生成模块名,例如'./userService.js' => 'userService'
    moduleName = modulePath.replace(prefix, '').replace(suffix, '')
    const value = moduleFiles[modulePath]
    modules[moduleName] = value
  })
  return modules
}
