/*
 * @Author: 谢卓勋 xiezx7@sany.com.cn
 * @Date: 2022-06-16 15:22:38
 * @LastEditors: 谢卓勋 xiezx7@sany.com.cn
 * @LastEditTime: 2022-06-20 16:52:34
 * @Description: 
 */
import { createRouter, createWebHashHistory } from 'vue-router';
import Layout from '@/framework/layout';
import dynamicRouter from './modules/dynamicRouter';
export const dynamicRouterList = dynamicRouter;
// 基础路由，不在此基础上修改
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/framework/views/redirect')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/framework/views/login/index.vue'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/framework/views/error-page/404.vue'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/framework/views/error-page/401.vue'),
    hidden: true
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes: constantRoutes,
});

export default router;
