<template>
  <component :is="type" v-bind="linkProps(to)">
    <slot />
  </component>
</template>

<script setup>
import { isExternal } from '@/framework/utils/validate';
import { useRoute } from 'vue-router';
const props = defineProps({
  to: {
    type: String,
    required: true
  },
  meta: {
    type: Object,
    default: () => { }
  }
});

const isExternalValid = computed(() => {
  return isExternal(props.to);
});
const type = computed(() => {
  if (isExternalValid.value) {
    return 'a';
  }
  return 'router-link';
});
const linkProps = (to) => {
  // debugger
  if (/^(\/extend\/)/.test(to)) {
    to = `/#${to}`
  }
  if (isExternalValid.value) {
    return {
      href: to,
      target: '_blank',
      rel: 'noopener'
    };
  }
  if (props.meta.blank) {
    return {
      href: to,
      target: '_blank',
      rel: 'noopener'
    };
  }
  return {
    to: to
  };
};
const state = reactive({
  levelList: null
});
onMounted(() => {
  // console.log(state.levelList)
  // console.log(props.to)
})
</script>

<style scoped lang="scss">

</style>
