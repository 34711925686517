/*
 * @Author: 谢卓勋 xiezx7@sany.com.cn
 * @Date: 2022-06-20 10:01:23
 * @LastEditors: 谢卓勋 xiezx7@sany.com.cn
 * @LastEditTime: 2022-07-14 19:22:45
 * @Description: 
 */
import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);
import router from './router';
import '@/framework/styles/index.scss'; // global css
import api from '@/framework/api/index';

app.config.globalProperties.$api = api;

import "@/framework/icons/fontIcon/iconfont.css"

// 地图工具
// window._AMapSecurityConfig = {
//   securityJsCode:'dfc11a39a5697169c4723b14299bb4c9',
// }

//import element-plus
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/dist/index.css';
app.use(ElementPlus, {
  size: 'default', //"" | "default" | "small" | "large"
  zIndex: 3000,
  locale: zhCn
});

//global mixin
import elementMixin from '@/framework/mixins/elementMixin';
app.mixin(elementMixin);
// import commonMixin from '@/mixins/commonMixin'
// app.mixin(commonMixin)
// import routerMixin from '@/mixins/routerMixin'
// app.mixin(routerMixin)
//import axios req
// import axiosReq from '@/framework/utils/axiosReq';
// app.config.globalProperties.$axiosReq = axiosReq

//svg-icon
//import svg-icon doc in  https://github.com/anncwb/vite-plugin-svg-icons/blob/main/README.zh_CN.md
import 'virtual:svg-icons-register';
import svgIcon from '@/framework/icons/SvgIcon.vue';
app.component('SvgIcon', svgIcon);

//element svg icon
import ElSvgIcon from '@/framework/components/ElSvgIcon.vue';
app.component('ElSvgIcon', ElSvgIcon);

//global mount dayjs
// import $dayjs from 'dayjs'
// app.config.globalProperties.$dayjs = $dayjs

//import global directive
import directive from '@/framework/directive';
directive(app);

//import global directive
import components from '@/framework/components';
components(app);

//import router  intercept
import './permission';

//error log  collection
import errorLog from '@/framework/hooks/useErrorLog';
errorLog(app);

//axios cancel req
window.__axiosPromiseArr = [];

//pinia
import { createPinia } from 'pinia';
import piniaPluginPersist from 'pinia-plugin-persist';
const pinia = createPinia();
pinia.use(piniaPluginPersist);
app.use(pinia);

import  store  from './store2';
app.use(store);

import {beacon} from "@/framework/utils/report_event.js"
window.beacon=beacon

app.use(router).mount('#app');
