import qs from 'qs'
import _ from 'lodash'

/**
 * 序列化查询字符串对象,skipEmpty为true时会跳过值为空字符串的字段,skipEmpty默认值为true
 * @param params
 * @param skipEmpty
 * @return {*}
 */
export default function stringify(params, skipEmpty = true) {
  // 避免修改原始对象
  const res = _.clone(params)

  /*
   * Qs不支持直接跳过值为空字符串的字段,但是支持跳过值为null的字段
   * 因此采用变通方案,先把值为空字符串的字段修改为null,再使用qs跳过值为null的字段
   */
  if (skipEmpty) {
    const keys = Object.keys(res)
    keys.forEach((key) => {
      if (res.hasOwnProperty(key) && res[key] === '') {
        res[key] = null
      }
    })
  }
  return qs.stringify(res, { skipNulls: true })
}
