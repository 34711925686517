/*
 * @Author: 谢卓勋 xiezx7@sany.com.cn
 * @Date: 2022-06-17 10:21:00
 * @LastEditors: 谢卓勋 xiezx7@sany.com.cn
 * @LastEditTime: 2022-06-20 17:24:43
 * @Description: 动态路由
 */
import Layout from '@/framework/layout';
import serviceRouter from "./serviceRouter"
import systemManageRouter from "./systemManageRouter"
import reportAnalysisRouter from "./reportAnalysisRouter"
import fleetRouter from "./fleetRouter"
import truckTwinRouter from "./truckTwinRouter"


export default [
  {
    code: 'iot:telematics:index',
    // path: '/#',
    name: 'telematics',
    alwaysShow: true,
    component: Layout,
    redirect: 'dashboard',
    meta: { title: '系统首页', elSvgIcon: 'Fold' },
    children: [{
      code: 'iot:telematics:system-index',
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/framework/serviceView/truckTwin/index.vue'),
      meta: { title: '系统首页', cachePage: true }
    },
    ]
  },
  ...serviceRouter,
  ...systemManageRouter,
  ...reportAnalysisRouter,
  ...fleetRouter,
  ...truckTwinRouter
];
