/*
 * @Author: 谢卓勋 xiezx7@sany.com.cn
 * @Date: 2022-06-16 15:22:37
 * @LastEditors: 谢卓勋 xiezx7@sany.com.cn
 * @LastEditTime: 2022-07-09 10:22:41
 * @Description: 
 */
import router from '@/framework/router';
import settings from './settings';
import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false }); // NProgress Configuration
import 'nprogress/nprogress.css';
import getPageTitle from '@/framework/utils/getPageTitle';
import { useUserStore } from '@/framework/store/user';
import { usePermissionStore } from '@/framework/store/permission';

const whiteList = ['/login', '/404', '/401']; // no redirect whitelist
router.beforeEach((to, from, next) => {
  // start progress bar
  NProgress.start();
  // set page title
  // document.title = getPageTitle(to.meta.title);
  const userStore = useUserStore();
  const { access_token } = userStore;
  const permissionStore = usePermissionStore();
  // console.log('beforeEach');
  if (access_token) {
    if (to.path === '/login') {
      next({ path: '/' });
    } else {
      const { roleMenus } = userStore;
      const { remoteRouteLoad } = permissionStore;
      if (roleMenus) {
        if (remoteRouteLoad) {
          next();
        } else {
          permissionStore
            .generateRoutes(roleMenus)
            .then(() => {
              
              next({ ...to, replace: true });
            })
            .catch((error) => {
              userStore.resetState();
              next({ path: '/login' });
            });
        }
      } else {
        userStore.resetState();
        next({ path: '/login' });
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
      if (settings.isNeedNprogress) NProgress.done();
    }
  }
});

router.afterEach(() => {
  if (settings.isNeedNprogress) NProgress.done();
});
