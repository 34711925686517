import { get } from "lodash";


export const baseApi = import.meta.env.VITE_APP_NODE_ENV == 'development' ? "/OpenApiPrefix/api" : '/api';



export function httpPost(url, data) {
    return new Promise((resolve, reject) => {
        return axiosReq({
            url: url,
            method: 'post',
            data,
        }).then((res) => {
            resolve(res)
        }).catch((err) => {
            //console.log("网络异常:查询车队列表失败!");
            reject(err)
        });
    })
}

export function httpGet(url,data,isParams=false){
    return new Promise((resolve, reject) => {
        return axiosReq({
            url: url,
            method: 'get',
            isParams:isParams,
            data,
        }).then((res) => {
            resolve(res)
        }).catch((err) => {
            //console.log("网络异常:查询车队列表失败!");
            reject(err)
        });
    })
}