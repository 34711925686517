import {
  getSlideCaptchaReq,
  getSmsVerifyCode,
  oauthLoginReq,
  chooseOrgReq,
  getMenuResourcesReq,
  logoutReq,
  getSignByLogin
} from '@/framework/api/userApi';
import router from '@/framework/router';
import { defineStore } from 'pinia';
import { usePermissionStore } from '@/framework/store/permission';
import { useTagsViewStore } from '@/framework/store/tagsView';
import CryptoJS from 'crypto-js';
import md5 from 'js-md5';
import { beacon } from '../utils/report_event';
import { dynamicRouterList } from '@/framework/router';
/**
 * 权限铺平, 返回所有有权限的菜单code一维数组
 * @param resources 有权限的菜单
 * @param flatResources 铺平的权限
 */
const flatAllResources = (resources, flatCodes) => {
  resources.forEach((resource) => {
    flatCodes.push(resource.code);
    if (resource.children && resource.children.length > 0) {
      flatAllResources(resource.children, flatCodes);
    }
  });
};

const resetRouter = () => {
  const permissionStore = usePermissionStore();
  const { remoteRoutes } = permissionStore;
  const asyncRouterNameArr = remoteRoutes.map((mItem) => mItem.name);
  asyncRouterNameArr.forEach((name) => {
    if (router.hasRoute(name)) {
      router.removeRoute(name);
    }
  });
};

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      access_token: "",
      org: {}, // 当前机构信息
      userInfo: {
        orgName: ""
      }, // 用户信息
      roleMenus: [], //权限菜单
      roleCodeList: [] // 权限code(一维数组)
    };
  },

  // 开启持久化
  persist: {
    enabled: true, // 启用
    strategies: [
      // storage 可选localStorage或sessionStorage
      // paths 给指定数据持久化
      { key: 'user', storage: localStorage, paths: ['org', 'userInfo', 'access_token', 'roleMenus', 'roleCodeList'] }
    ]
  },

  getters: {
    orgId: (state) => state.userInfo.orgId,
    userId: (state) => state.org.userId,
    access_token: (state) => state.userInfo.access_token
  },

  actions: {
    SET_ORG(org) {
      this.$patch((state) => {
        state.org = org;
      });
    },
    SET_LOGIN_INFO(userInfo) {
      this.$patch((state) => {
        state.userInfo = userInfo;
        state.access_token = userInfo.access_token;
      });
    },
    SET_ROLE_MENUS(roleMenus) {
      let flatCodes = [];
      flatAllResources(roleMenus, flatCodes);
      this.$patch((state) => {
        state.roleMenus = roleMenus;
        state.roleCodeList = flatCodes;
      });
    },
    // 获取滑块验证码
    getSlideCaptcha(username) {
      return new Promise((resolve, reject) => {
        getSlideCaptchaReq(username)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取短信验证码
    // getSmsCaptcha({ username, type }) {
    //   return new Promise((resolve, reject) => {
    //     getSmsVerifyCode({ username })
    //       .then((res) => {
    //         if (res.code != 200) {
    //           reject(res);
    //         }
    //         resolve(res);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
    preLogin({ username, type, password }) {
      let passwordAes = this.encrypt(password)
      // console.log("原密码：",password,"  新密码：",passwordAes,",type = ",type);
      return new Promise((resolve, reject) => {
        getSmsVerifyCode({ username, type: 0 }).then(() => {
          // getSignByLogin({ username, type, password: type == 'phone' ? md5.base64(password) : password }).then(res => {
          getSignByLogin({ username, type, password: type == 'phone' ? md5.base64(password) : passwordAes }).then(res => {
            if (res.code != 200) {
              reject(res)
            } else {
              resolve(res)
            }
          }).catch(err => {
            reject(err)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 登录
    oauthLogin(loginForm) {
      let { checkCode, sign } = loginForm;
      return new Promise((resolve, reject) => {
        oauthLoginReq({ checkCode, sign }).then((res) => {
          const { tokenInfo: { additional_user, access_token } } = res
          const userId = additional_user.userId || null
          const org = { orgId: additional_user.org || null }
          const orgCatetoryId = additional_user.categorys[0].orgCategoryId || null

          const errorConfig = {
            org: '未配置组织,请联系管理员',
            valid: '该账号已被禁用,请联系管理员',
            workStatus: '您现已离职该公司,暂不允许操作此公司数据!'
          };
          if (!org) {
            return reject(errorConfig[org]);
          }
          this.SET_LOGIN_INFO({ access_token, ...additional_user })
          beacon.setOpenId(additional_user.phone)
          beacon.onUserAction('login', {
            '页面名称': '登录',
            user_name: additional_user.accountName,
            user_id: additional_user.userId,
            phone: additional_user.phone
          });
          this.getMenuResources({ orgCatetoryId, bundleId: 'com.sany.iov.truck.web' }).catch(err => {
            reject(err)
          })

          // const { userId, orgs } = res;
          // const org = orgs.find((item) => item.id === 14) || null;
          // const errorConfig = {
          //   org: '未配置组织,请联系管理员',
          //   valid: '该账号已被禁用,请联系管理员',
          //   workStatus: '您现已离职该公司,暂不允许操作此公司数据!'
          // };
          // if (!org) {
          //   return reject(errorConfig[org]);
          // }
          // const { valid, workStatus } = org;
          // if (!valid) {
          //   return reject(errorConfig[valid]);
          // }
          // if (!workStatus) {
          //   return reject(errorConfig[workStatus]);
          // }
          // org.userId = userId;
          // this.SET_ORG(org);
          // this.chooseOrg({
          //   userId,
          //   orgId: org.id,
          //   accountId: org.accountId
          // });
          // resolve(res);
        })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 选择机构
    chooseOrg(params) {
      return new Promise((resolve, reject) => {
        chooseOrgReq(params)
          .then((res) => {
            const { permission, access_token, additional_user: userInfo } = res;
            if (!permission) {
              return reject('无访问权限,请联系管理员');
            }
            if (!userInfo || !access_token) {
              return reject('无访问权限,请联系管理员');
            }
            userInfo.access_token = access_token;
            this.SET_LOGIN_INFO(userInfo);
            this.getMenuResources({ orgCatetoryId: 10 });
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    // 获取权限菜单
    getMenuResources(params) {
      return new Promise((resolve, reject) => {
        getMenuResourcesReq(params)
          .then((result) => {
            let localCodes = dynamicRouterList.map((item) => {
              return item.code
            })
            //后台的菜单过滤
            let res = result.filter((item) => {
              return localCodes.indexOf(item.code) >= 0
            })
            // console.log("用户所有权限", res)
            this.SET_ROLE_MENUS(res);
            if (res) {
              useTagsViewStore().delAllVisitedViews()
              const permissionStore = usePermissionStore();
              // 默认选择顶部菜单栏第一项
              permissionStore.setSelectHeaderId(res[0].id)
              permissionStore
                .generateRoutes(res)
                .then((route) => {
                  // console.log(route)
                  permissionStore.setRoutes(route)
                  router.push({ path: '/' });
                })
                .catch((e) => {
                  this.resetState();
                  router.push({ path: '/login' });
                });
            } else {
              this.resetState();
              router.push({ path: '/login' });
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    // 退出登录
    logout() {
      const params = { username: this.userInfo.phone };
      return new Promise((resolve, reject) => {
        logoutReq(params)
          .then(() => {
            this.resetState();
            resolve(null);
          })
          .catch((error) => {
            this.resetState();
            reject(error);
          });
      });
    },
    resetState() {
      return new Promise((resolve) => {
        this.SET_ORG({});
        this.SET_LOGIN_INFO({}, '');
        this.SET_LOGIN_INFO({}, '');
        this.SET_ROLE_MENUS([]);
        resetRouter();
        const tagsViewStore = useTagsViewStore();
        tagsViewStore.delAllViews();
        const permissionStore = usePermissionStore();
        permissionStore.setRemoteRouteLoad(false);
        resolve(null);
      });
    },
    /**
        * 加密方法
        * */
    encrypt(word) {
      // 处理非法输入
      if (word === null || word === undefined || word === '' || typeof word !== 'string') {
        return '';
      }

      var key = CryptoJS.enc.Utf8.parse('zhonghuan_aeskey');

      var srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.toString();
    },
  }
});
