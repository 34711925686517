import Layout from '@/framework/layout';
export default [
    {
        code: 'iov:reportAnalysis',
        path: '/reportAnalysis',
        name: 'reportAnalysis',
        alwaysShow: true,
        component: Layout,
        redirect: 'dynamicOil',
        meta: { title: '报表分析', elSvgIcon: 'Fold' },
        children: [
            {
                code: 'iov:dynamicOil',
                path: '/dynamicOil',
                name: 'dynamicOil',
                component: () => import('@/framework/serviceView/reportAnalysis/dynamicCommon/oilModule.vue'),
                meta: { title: '动力分析（燃油）', cachePage: true }
            },
            {
                code: 'iov:dynamicElectric',
                path: '/dynamicElectric',
                name: 'dynamicElectric',
                component: () => import('@/framework/serviceView/reportAnalysis/dynamicCommon/eleModule.vue'),
                meta: { title: '动力分析（电动）', cachePage: true }
            },
            {
                code: 'iov:marketOil',
                path: '/marketOil',
                name: 'marketOil',
                component: () => import('@/framework/serviceView/reportAnalysis/marketCommon/oilModule.vue'),
                meta: { title: '营销分析（燃油）', cachePage: true }
            },
            {
                code: 'iov:marketElectric',
                path: '/marketElectric',
                name: 'marketElectric',
                component: () => import('@/framework/serviceView/reportAnalysis/marketCommon/eleModule.vue'),
                meta: { title: '营销分析（电动）', cachePage: true }
            },
        ]
    }
];
