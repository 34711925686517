import axiosReq from "../utils/axiosReq";

/*
 * @Author: 谢卓勋 xiezx7@sany.com.cn
 * @Date: 2022-06-20 10:01:23
 * @LastEditors: 谢卓勋 xiezx7@sany.com.cn
 * @LastEditTime: 2022-07-15 17:49:16
 * @Description: 登录相关的接口
 */
const serverPrefix = import.meta.env.VITE_APP_NODE_ENV == 'development' ? "/ApiPrefix" : '';
// 获取图形验证码
export function getSlideCaptchaReq(username) {
  return axiosReq({
    url: `${serverPrefix}/auth/user/v1/auth/verify/picture?username=${username}`,
    method: 'get'
  });
}

// 获取短信验证码
export function getSmsVerifyCode(data) {
  return axiosReq({
    url: `${serverPrefix}/api/auth/uaa/oauth/verify/picture`,
    method: 'get',
    data,
    isParams: true,
  });
}

// 通过账号密码获取sign码
export function getSignByLogin(data) {
  return axiosReq({
    url: `${serverPrefix}/api/auth/uaa/user/admin/oauth/iov/login`,
    method: 'post',
    data,
  })
}

// 登录
export function oauthLoginReq(data) {
  // let data = new FormData();
  // if (params.authType === 'password') {
  //   data.append('username', params.username);
  //   data.append('password', params.password);
  //   data.append('captcha', params.captcha);
  //   data.append('authType', params.authType);
  // } else {
  //   data.append('username', params.username);
  //   data.append('captcha', params.captcha);
  //   data.append('authType', params.authType);
  // }
  // data.append('terminal', 'platform.xingbida.com.cn');

  // return axiosReq({
  //   url: `${serverPrefix}/api/auth/uaa/user/admin/oauth/ultimately/login`,
  //   data,
  //   method: 'post'
  // });
  data = Object.assign(data,{bundleId:'com.sany.truck.iov.web'})
  return axiosReq({
    url: `${serverPrefix}/api/auth/uaa/user/admin/oauth/ultimately/login`,
    data,
    method: 'post'
  });
}

// 选择公司
export function chooseOrgReq(data) {
  data = Object.assign(data,{bundleId:'com.sany.truck.iov.web'})
  return axiosReq({
    url: `${serverPrefix}/auth/oauth/chooseOrg`,
    data,
    isParams: true,
    method: 'get'
  });
}

// 获取模块下的菜单
export function getMenuResourcesReq(data) {
  return axiosReq({
    url: `${serverPrefix}/api/org/v1/common/category/resources`,
    data,
    isParams: true,
    method: 'get'
  });
}

// 获取模块下的菜单
export function logoutReq(data) {
  return axiosReq({
    url: `${serverPrefix}/api/auth/uaa/oauth/logout`,
    method: 'post',
    data
  });
}
