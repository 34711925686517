
/**
 * v-fullscreen
 * 全屏某个元素
 * 接收参数：需要放大元素的id或者class
 */
let dom = null
function handleClick() {
    let isFull = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullscreenElement || document.msFullScreenElement
    if (isFull) {
        document.exitFullscreen
            ? document.exitFullscreen()
            : document.webkitExitFullscreen
                ? document.webkitExitFullscreen()
                : document.mozCancelFullScreen
                    ? document.mozCancelFullScreen()
                    : document.msExitFullscreen && document.msExitFullscreen()
    } else {
        dom.requestFullscreen
            ? dom.requestFullscreen()
            : dom.webkitRequestFullscreen
                ? dom.webkitRequestFullscreen()
                : dom.mozRequestFullScreen
                    ? dom.mozRequestFullScreen()
                    : dom.msRequestFullscreen && dom.msRequestFullscreen()
    }
}

export default {
    mounted(el, binding) {
        let { value: { screenEl } } = binding
        dom = document.querySelector(screenEl)
        el.addEventListener('click', handleClick)
    },
    updated(el, binding) {
        // el.copyData = binding.value;
    },
    beforeUnmount(el) {
        el.removeEventListener('click', handleClick);
    },
};

