<!--
 * @Author: 谢卓勋 xiezx7@sany.com.cn
 * @Date: 2022-06-16 15:22:38
 * @LastEditors: 谢卓勋 xiezx7@sany.com.cn
 * @LastEditTime: 2022-06-16 17:20:55
 * @Description: 
-->
<template>
  <div id="Sidebar" class="reset-menu-style">
    <!--logo-->
    <!-- <Logo :collapse="!isCollapse" /> -->
    <!--router nav-->
    <el-scrollbar>
      <el-menu class="el-menu-vertical" :default-active="activeMenu" :collapse="!isCollapse" :unique-opened="true"
        :collapse-transition="false" :background-color="scssJson.menuBg" :text-color="scssJson.menuText"
        :active-text-color="scssJson.menuActiveText" mode="vertical">
        <sidebar-item v-for="itemRoute in routes" :key="itemRoute.path" :item="itemRoute" :base-path="itemRoute.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import Logo from './Logo.vue';
import SidebarItem from './SidebarItem.vue';
import { useAppStore } from '@/framework/store/app';
import { useUserStore } from '@/framework/store/user';
import { usePermissionStore } from '@/framework/store/permission';
//导入配置文件

const appStore = useAppStore();
const userStore = useUserStore();
let pathMap = {
  "/fleet/List": "/fleetList",
  "/fleet/fleetOperation": "/fleetOperation",
  "/fleet/energy": "/fleetEnergy",
  "/fleet/trip": "/fleetTrip",
  "/fleet/my/list": "/fleetMyList",
  "/fleet/my/driverList": "/fleetMyDriverList",
  "/fleet/my/truckList": "/fleetMyTruckList",
}
const userRoleMenus = computed(() => userStore.roleMenus)
const settings = computed(() => {
  return appStore.settings;
});

const route = useRoute();
const permissionStore = usePermissionStore();
// 获取的router中的权限
const routes = computed(() => {
  return permissionStore.routes;
});
const isCollapse = computed(() => {
  return appStore.sidebar.opened;
});

//change  scss variable to js
const dillScssExportToJson = (scssExportJson) => {
  const jsonString = scssExportJson.replace(/:export\s*/, '').replace(/[\s+\r\n]/g, '');
  const scssJson = {};
  jsonString
    .slice(1, jsonString.length - 2)
    .split(';')
    .forEach((fItem) => {
      const arr = fItem.split(':');
      scssJson[arr[0]] = arr[1];
    });
  return scssJson;
};
watch(() => route.path, (value) => {

  checkPath(value)

})

const checkPath = (pathUrl) => {
  let inCurrentRoutes = isInCurrentRoutes(pathUrl)
  if (inCurrentRoutes) {
    return
  }
  let menus = userRoleMenus.value
  let findMenus = getChosseMenus(pathUrl, menus)
  if (!findMenus) {
    return
  }
  let size = findMenus.length ?? 0
  if (size == 0) {
    return
  }
  permissionStore.setSelectHeaderId(findMenus[size - 1].id)
}

const isInCurrentRoutes = (pathUrl) => {
  let items = routes._value
  let size = items?.length ?? 0
  for (let i = 0; i < size; i++) {
    let item = items[i]
    if (pathUrl == item.path) {
      return true
    }
  }
  return false
}
const getChosseMenus = (pathUrl, menuList) => {
  let length = menuList.length ?? 0
  for (let i = 0; i < length; i++) {
    let item = menuList[i]
    if (item.routeUrl) {
      if (item.routeUrl.indexOf(pathUrl) >= 0) {
        return [item]
      }
      if (pathUrl == pathMap[item.routeUrl]) {
        return [item]
      }
    }
    let children = item.children ?? null
    if ((children?.length ?? 0) > 0) {
      let findMenus = getChosseMenus(pathUrl, children)
      if (findMenus) {
        findMenus.push(item)
        return findMenus
      }
      continue
    }
  }
  return null
}
//get scss variable
import scssExportJson from '@/framework/styles/variables-to-js.scss';
const scssJson = dillScssExportToJson(scssExportJson);
const activeMenu = computed(() => {
  const { meta, fullPath } = route;
  // if set path, the sidebar will highlight the path you set
  if (meta.activeMenu) {
    return meta.activeMenu;
  }
  return fullPath;
})

onMounted(() => {
  checkPath(route.path)
})
</script>

<style lang="scss" scoped>
:deep() {
  .el-scrollbar__wrap {
    padding-bottom: 10vh;
  }

  .el-menu {
    border-right: none;

    .el-sub-menu {
      a {
        position: relative;

        &::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background: $menuText;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 30px;
          z-index: 999;
        }
      }

      .router-link-active.router-link-exact-active {
        &::before {
          background: $menuActiveText;
        }
      }
    }

    .router-link-active.router-link-exact-active {
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        width: 3px;
        height: 100%;
        background: $menuActiveText;
        position: absolute;
        top: 0px;
        right: 0px;
      }

      .el-menu-item {
        background-color: $menuActiveBg;
        color: $menuActiveText;
      }
    }



    .el-sub-menu.is-active.is-opened {
      .el-sub-menu__title {
        span {
          color: $menuActiveText;
        }
      }
    }
  }

  .el-menu-vertical {
    width: $sideBarWidth;

    .el-menu-item,
    .el-sub-menu__title {
      font-weight: 600;
      font-family: PingFang HK-Semibold, PingFang HK;
      font-size: 8px;

      // &.is-active,
      &:hover {
        background-color: $menuActiveBg;
      }
    }
  }
}
</style>
