// import Vue from 'vue'
// import Vuex from 'vuex'
// import fleetData from './modules/fleetData'
// // import user from './modules/user'
// // import permission from './modules/permission'
// // import getters from './getters'
 
// Vue.use(Vuex)
 
// const store = new Vuex.Store({
//   modules: {
//     // app,
//     // user,
//     fleetData
//   },
//   getters
// })
 
// export default store



import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import fleetData from './modules/fleetData'

// export default createStore({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//     fleetData
//   }
// })

const store2 = createStore({
  state: {
    
  },
  mutations: {
  },
  actions: {
  
  },
  modules: {
    fleetData
  },
   // 配置插件
   plugins: [
    // 默认储存在localstorage
    createPersistedState({
        // 本地储存名
        key: 'state_data',
        // 指定模块
        paths: ['fleetData']
    })
],
})

export default store2