// 上报成功回调，可根据实际业务调整，这里给到一种打印到控制台的方案
const success = e => {
    let obj=JSON.parse(e)
    // console.log('onReportSuccess : ' , obj[0].params.page_path);
    // // console.log('onReportSuccess : ' , e);
};
// 上报失败回调，可根据实际业务调整，这里给到一种打印到控制台的方案
const fail = e => {
    // console.log('onReportFail : ' + e);
    console.log('onReportFail : ' , e);
};

export const beacon = new BeaconAction({
    appkey: 'LCZP2S1C3XN54TNR', // 数据资源appkey, 必填
    reportUrl: 'https://ubap-report.sany.com.cn/logserver/analytics/upload?tp=js', // 默认为 SAAS 服务的上报地址，私有化客户请在此传入您的上报地址
    versionCode: '', //项目版本, 选填, 不设置会走默认配置
    channelID: '', //渠道, 选填, 不设置会走默认配置
    openid: '', // 用户 id, 选填, 不设置会走默认配置；上报到平台后对应为uid字段
    delay: 5000, // 普通事件延迟上报时间(单位毫秒), 默认 5000(5 秒),选填
    maxDBCount: 10000, // 最大存储条数, 默认 10000(条), 设置区间10000-50000,选填
    sessionDuration: 60 * 1000, // session 变更的时间间隔, 一个用户持续 30 分钟(默认值)没有 任何上报则算另一次 session, 每变更一次session上报一次启动事件(rqd_applaunched),使用毫秒 (ms), 最小值 30 秒, 选填, 不设置会走默认配置
    onReportSuccess: success, // 上报成功回调, 选填, 不设置会走默认配置
    onReportFail: fail, // 上报失败回调, 选填, 不设置会走默认配置
});


// export default beacon

// export default {
//     beacon
//   }