import Layout from '@/framework/layout';
export default [
    {
        code: 'iov:truckTwin',
        path: '/truckTwin',
        name: 'TruckTwin',
        alwaysShow: true,
        component: Layout,
        redirect: 'TruckTwinIndex',
        meta: { title: '重卡孪生', elSvgIcon: 'Fold' },
        children: [

            {
                code: 'iov:truckTwinPageList',
                path: '/truckTwinPageList',
                name: 'TruckTwinPageList',
                component: () => import('@/framework/serviceView/truckTwin/truckTwinPageList/indexOld.vue'),
                meta: { title: '车辆列表', cachePage: true }
            },
            {
                code: 'iov:truckTwinPageListNew',
                path: '/truckTwinPageListNew',
                name: 'TruckTwinPageListNew',
                component: () => import('@/framework/serviceView/truckTwin/truckTwinPageList/index.vue'),
                meta: { title: '车辆列表新', cachePage: true }
            },
            {
                code: 'iov:truckTwin:Online',
                path: '/truckTwinOnline',
                name: 'TruckTwinOnline',
                component: () => import('@/framework/serviceView/truckTwin/truckTwinOnline/index.vue'),
                meta: { title: '实时在线', cachePage: true }
            },
            {
                code: 'iov:energyAnalysis',
                path: '/energyAnalysis',
                name: 'EnergyAnalysis',
                component: () => import('@/framework/serviceView/truckTwin/energyAnalysis/index.vue'),
                meta: { title: '能耗分析', cachePage: true },
            },
            {
                code: 'iov:truckTrip',
                path: '/truckTrip',
                name: 'TruckTrip',
                component: () => import('@/framework/serviceView/truckTwin/truckTrip/index.vue'),
                meta: { title: '车辆行程', cachePage: true }
            },
            {
                code: 'iov:truckTripDetail',
                path: '/truckTripDetail',
                name: 'TruckTripDetail',
                component: () => import('@/framework/serviceView/truckTwin/truckTrip/truckTripDetail.vue'),
                meta: { title: '车辆轨迹', cachePage: true },
            },
            {
                code: 'iov:truckHistoryData',
                path: '/truckHistoryData',
                name: 'truckHistoryData',
                component: () => import('@/framework/serviceView/truckTwin/historyData/index.vue'),
                meta: { title: '历史数据', cachePage: true }
            },
        ]
    },
];
