import axiosReq from "../utils/axiosReq";
import dayjs from "dayjs"
const serverPrefix = import.meta.env.VITE_APP_NODE_ENV == 'development' ? "/ApiPrefix/api" : '/api';
// const serverPrefix = import.meta.env.VITE_APP_NODE_ENV == 'development' ? "/ApiPrefix" : '/api';

// import axiosDevReq from "../utils/axiosDevReq"
// const axiosReq = axiosDevReq
// const serverPrefix = import.meta.env.VITE_APP_NODE_ENV == 'development' ? "/OpenApiPrefix/vd/sanymgmt" : '/api';


// 重卡孪生大屏 通过关键字模糊搜索车辆
export function queryTruckByKeywordReq(data) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/mgmt/online-map/selectTruck?queryString=${data.keyWord}`,
    method: 'get',
    data,
    isParams: true,
  });
}
// 查询车辆实时状况
export function queryTruckRealInfoReq(data) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/mgmt/online-map/selectRealInfo`,
    method: 'post',
    data,
  });
}

// 查询车辆画像信息
export function queryTruckTripInfoReq(data) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/mgmt/online-map/selectTripInfo`,
    method: 'post',
    data,
  });
}

// 查询车辆实时位置
export function queryTruckRealLocaltionReq(data, cancelToken) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/mgmt/online-map/selectRealTripInfo/${data.vin}`,
    method: 'get',
    data,
    cancelToken
    // isParams: true,
  });
}


// 查询行程列表
export function queryTruckTripList(data) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/mgmt/mileage-fuel/detail/list`,
    method: 'get',
    data,
    isParams: true,
  })
}


// 查询车辆行驶轨迹
export function queryTruckLocusReq(data) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/mgmt/monitor-trucks/detail/transit-replay`,
    method: 'get',
    data,
    isParams: true,
  })
}


// export function queryTruckLocusReq(data) {
//   return axiosReq({
//     url: `${serverPrefix}/board/v1/api/drive-analysis/behavior/trip`,
//     method: 'get',
//     data,
//     isParams: true,
//   })
// }


// 能耗分析 按日分析
export function queryTruckFuleByDayReq(data) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/mgmt/mileage-fuel/detail/list`,
    method: 'get',
    data,
    isParams: true,
  })
}

// 能耗分析 按油耗分析
export function queryTruckFuleByFuelReq(data) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/mgmt/iot-rda/oil-wear/getFuelingTimes`,
    method: 'get',
    data,
    isParams: true,
  })
}


// 能耗分析 按时间查询轨迹信息
export function queryTruckLocusByTimeReq(data) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/mgmt/iot-rda/oil-wear/route-map`,
    method: 'get',
    data,
    isParams: true,
  })
}
// 车辆轨迹、轨迹信息查询
export function queryTrackReq(data) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/mgmt/iot-rda/oil-wear/route-map-time`,
    method: 'post',
    data,
    // isParams: true,
  })
}

// 实时在线 故障预警
export function queryTruckFaultByVinCodeReq(data) {
  return axiosReq({
    url: `${serverPrefix}/board/v1/api/fault/diagnosis/getCurrentFault`,
    method: 'get',
    data,
    isParams: true,
  })
}

//查询车辆列表
export function queryTruckListReq(data) {
  var url = `${serverPrefix}/board/v1/mgmt/truck/trucks-new`;
  //  var url = `${serverPrefix}/board/v1/mgmt/truck/trucks`;
  // console.log("查询车辆列表 url = ", url);
  return axiosReq({
    url: url,
    method: 'get',
    data,
    isParams: true,
  })
}

//获取报告id
export function queryAnalysisPaperIdReq(data) {
  var url = `${serverPrefix}/board/v1/mgmt/iot-rda/oil-wear/getMaintainDateStartAnalysis`;
  return axiosReq({
    url: url,
    method: 'get',
    data,
    isParams: true,
  })
}

//通过报告id获取报告
export function queryAnalysisPaperByIdReq(data) {
  var url = `${serverPrefix}/board/v1/mgmt/iot-rda/oil-wear/getReport`;
  if(data.endDate){
    data.endDate=dayjs(data.endDate).add(-1,"day").format("YYYY-MM-DD")
  }
  var url = `${serverPrefix}/board/v1/mgmt/iot-rda/oil-wear/getReport`;
  return axiosReq({
    url: url,
    method: 'get',
    data,
    isParams: true,
  })
}


//添加车辆
export function addTruckReq(data) {
  var url = `${serverPrefix}/board/v1/mgmt/truck`;//TruckDetailReq
  console.log("添加车辆 url = ", url);
  return axiosReq({
    url: url,
    method: 'post',
    data,
    // isParams: true,
  })
}

//更新车辆
export function updateTruckReq(data) {
  var url = `${serverPrefix}/board/v1/mgmt/truck`;//TruckDetailReq
  console.log("更新车辆 url = ", url);
  return axiosReq({
    url: url,
    method: 'put',
    data,
    // isParams: true,
  })
}

// //更新车辆
// export function updateTruckReq(data) {
//   var url=`/localApi/board/v1/mgmt/truck`;//TruckDetailReq
//   console.log("更新车辆 url = ",url);
//   return axiosReq({
//     url:url,
//     method: 'put',
//     data,
//     // isParams: true,
//   })
// }


//删除车辆
export function deleteTruckReq(vinCode) {

  // /truck/{vinCode}
  var url = `${serverPrefix}/board/v1/mgmt/truck/` + vinCode;//TruckDetailReq
  console.log("删除车辆 url = ", url);
  return axiosReq({
    url: url,
    method: 'delete',
    data,
    // isParams: true,
  })
}

//查看车辆信息
export function viewTruckReq(vinCode) {

  // /truck/{vinCode}
  var url = `${serverPrefix}/board/v1/mgmt/truck/` + vinCode;//TruckDetailReq
  // console.log("查看车辆信息url = ", url);
  return axiosReq({
    url: url,
    method: 'get',
    // data,
    // isParams: true,
  })
}


//查询车主审核信息

export function searchReviewOwnerInfoReq(data) {

  // /truck/{vinCode}
  var url = `${serverPrefix}/board/v1/mgmt/truck/get-owner-apply-info-v1`;
  return axiosReq({
    url: url,
    method: 'post',
    data,
    // isParams: true,
  })
}


//根据车型码查询车型信息

export function getTruckModelInfoReq(modelCode) {

  var url = `${serverPrefix}/board/v1/mgmt/truck-model/get-by-code/${modelCode}`;
  return axiosReq({
    url: url,
    method: 'get',
    // data,
    // isParams: true,
  })
}


//车辆回库

export function truckBackReq(vinCode) {

  var url = `${serverPrefix}/board/v1/mgmt/truck/back/${vinCode}`;
  return axiosReq({
    url: url,
    method: 'put',
    // data,
    // isParams: true,
  })
}
//审核-成为车主/司机
export function applyOwnerReq(userTruckMapId, data, accountId) {

  var url = `${serverPrefix}/board/v1/bom/web/apply-owner-audit/${userTruckMapId}`;
  data.__accountId = accountId;
  var request = axiosReq({
    url: url,
    method: 'put',
    data
    // isParams: true,
  });
  return request;
}



export function queryConditionsReq(data) {
  console.log(data)
  var url = `${serverPrefix}/board/v1/mgmt/iot-rda/oil-wear/getConditions`;
  return axiosReq({
    url: url,
    method: 'get',
    timeout: 30000,
    data,
    isParams: true,
  })
}

// 获取车辆历史数据(燃油车)
export function queryTruckHistoryReq(data) {
  console.log(data)
  var url = `${serverPrefix}/board/v1/mgmt/truck/history/list`;
  return axiosReq({
    url: url,
    method: 'get',
    timeout: 30000,
    data,
    isParams: true,
  })
}
// 获取车辆历史数据(油电混合车)
export function queryOeHybirdTruckHistoryReq(data) {
  console.log(data)
  var url = `${serverPrefix}/board/vcc/oe-hybird/getHevRealtimeDataList`;
  return axiosReq({
    url: url,
    method: 'get',
    timeout: 60000,
    data,
    isParams: true,
  })
}


// 获取车辆属性信息
export function queryTruckPropertsHttpReq(vin,userId) {
 var data={
  vin:vin,
  userId:userId
 }
 const prefix = import.meta.env.VITE_APP_NODE_ENV == 'development' ? "/OpenApiPrefix/api" : '/api';
  // var url = `${serverPrefix}/board/v1/mgmt/truck-dms/selectTruckInfo`;
  var url = `${prefix}/board/v1/mgmt/truck-dms/selectTruckInfo`;
  return axiosReq({
    url: url,
    method: 'post',
    data,
  })
}


// 更新车辆属性快捷方式列表
export function updateTruckPropertShortcutsHttpReq(idArrayText,userId) {
  var data={
    dictionaryId:idArrayText,
   userId:userId
  }
  const prefix = import.meta.env.VITE_APP_NODE_ENV == 'development' ? "/OpenApiPrefix/api" : '/api';
   // var url = `${serverPrefix}/board/v1/mgmt/truck-dms/updateKeyInfo`;
   var url = `${prefix}/board/v1/mgmt/truck-dms/updateKeyInfo`;
   return axiosReq({
     url: url,
     method: 'post',
     data,
   })
 }





