import axiosReq from "../utils/axiosReq";
const serverPrefix = import.meta.env.VITE_APP_NODE_ENV == 'development' ? "/ApiPrefix" : '';

// 动力分析 获取统计信息
export function queryTotalDayInfo(data) {
  return axiosReq({
    url: `${serverPrefix}api/board/v1/mgmt/capacity-analysis/total-today-info`,
    method: 'get',
    data,
    isParams: true,
  });
}

// 动力分析- 获取平均和趋势信息
export function queryAvgTrendInfo(data) {
  return axiosReq({
    url: `${serverPrefix}api/board/v1/mgmt/capacity-analysis/avg-and-trend-info`,
    method: 'get',
    data,
    isParams: true,
  });
}

// 动力分析- 获取热力图信息
export function queryHeatMapInfo(data) {
  return axiosReq({
    url: `${serverPrefix}api/board/v1/mgmt/capacity-analysis/heat-map-info`,
    method: 'get',
    data,
    isParams: true,
  });
}

// 动力分析- 获取热力图信息
export function queryAreaWorkingRankInfo(data) {
  return axiosReq({
    url: `${serverPrefix}api/board/v1/mgmt/capacity-analysis/get-area-working-rank-info`,
    method: 'get',
    data,
    isParams: true,
  });
}

// 动力分析- 获取热力图信息
export function queryAreaCommonRankInfo(data) {
  return axiosReq({
    url: `${serverPrefix}api/board/v1/mgmt/capacity-analysis/get-area-common-rank-info`,
    method: 'get',
    data,
    isParams: true,
  });
}


// 营销分析 获取统计信息
export function queryTotalDayMarketInfo(data) {
  return axiosReq({
    url: `${serverPrefix}api/board/v1/mgmt/marketing-kanban/get-critical-info`,
    method: 'get',
    data,
    isParams: true,
  });
}

// 营销分析 获取统计信息
export function queryHistoryMarketInfo(data) {
  return axiosReq({
    url: `${serverPrefix}api/board/v1/mgmt/marketing-kanban/get-history-info`,
    method: 'get',
    data,
    isParams: true,
  });
}

// 营销分析 获取趋势信息
export function queryTrendMarketInfo(data) {
  return axiosReq({
    url: `${serverPrefix}api/board/v1/mgmt/marketing-kanban/get-trend-info`,
    method: 'get',
    data,
    isParams: true,
  });
}

// 营销分析 获取排名信息
export function queryRankListMarketInfo(data) {
  return axiosReq({
    url: `${serverPrefix}api/board/v1/mgmt/marketing-kanban/get-area-rank-info`,
    method: 'get',
    data,
    isParams: true,
  });
}

// 营销分析 获取热力图
export function queryHeatMapMarketInfo(data) {
  return axiosReq({
    url: `${serverPrefix}api/board/v1/mgmt/marketing-kanban/get-heat-map-info`,
    method: 'get',
    data,
    isParams: true,
  });
}



