<template>
  
  <!-- <router-view  /> -->


  <router-view v-slot="{ Component }" >
      <keep-alive  exclude="Login">
        <component :is="Component"  />
      </keep-alive>
    </router-view>
</template>
<script setup>
import { useAppStore } from '@/framework/store/app';

const appStore = useAppStore();
const settings = computed(() => {
  return appStore.settings;
});
onBeforeMount(() => {
  //   console.log(appContext.config.globalProperties.$api)
})
</script>
