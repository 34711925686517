import { resolveRequire } from '@/framework/utils/resolve';
// 导入当前文件夹下面所有文件
// const moduleFiles = require.context('./', true, /\.js$/)
//require.context('./modules', true, /\.js$/)

const moduleFiles = import.meta.globEager('./*.js');

// 解析导入的文件
const framework = resolveRequire(moduleFiles);

export default framework;
