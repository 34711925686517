<template>
  <div :class="classObj" class="layout-wrapper">
    <!--NavBar-->
    <Navbar v-if="settings.showTopNavbar" />
    <!--main container-->
    <div class="main-container">
      <Sidebar v-if="settings.showLeftMenu" class="sidebar-container" />
      <TagsView v-if="settings.showTagsView" />
      <AppMain v-watermark='{
        "text": waterMarkText, "font": "12px Microsoft JhengHei"
      }' />
    </div>
  </div>
</template>
<!--原理vue2.0-->
<script>
/*可以设置默认的名字*/
export default {
  name: 'Layout',
}
</script>

<script setup>
import { Sidebar, Navbar, AppMain, TagsView } from './components';

const appStore = useAppStore();
const { userInfo: { accountName, phone } } = useUserStore();

const waterMarkText = computed(() => {
  return `SANY ${accountName || ''} ${phone || ''}`
})
const opened = computed(() => {
  return appStore.sidebar.opened;
});

const settings = computed(() => {
  return appStore.settings;
});
const classObj = computed(() => {
  return {
    closeSidebar: !opened.value,
    hideSidebar: !settings.value.showLeftMenu
  };
});

//import ResizeHook to   listen  page size that   open or close
import ResizeHook from './hook/ResizeHandler';
import { useAppStore } from '@/framework/store/app';
import { useUserStore } from '@/framework/store/user';

ResizeHook()
</script>

<style lang="scss" scoped>
.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: $sideBarWidth;
  position: relative;
}

.sidebar-container {
  transition: width 0.28s;
  width: $sideBarWidth !important;
  background-color: $menuBg;
  height: 100%;
  position: fixed;
  font-size: 0;
  top: $navBarHeight;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
  border-right: 1px solid #ECECEC;
}

.closeSidebar {
  .sidebar-container {
    width: 54px !important;
  }

  .main-container {
    margin-left: 54px !important;
  }
}

.hideSidebar {
  .sidebar-container {
    width: 0 !important;
  }

  .main-container {
    margin-left: 0;
  }
}
</style>
