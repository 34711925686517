const mixin = {
  data() {
    return {
      /* table*/
      pageSizesList: [10, 20, 50, 100, 200],
      // 总条数
      totalNum: 0
    }
  },
  methods: {
    /*
     * 通知弹框
     * message：通知的内容
     * type：通知类型
     * duration：通知显示时长（ms）
     * */
    elMessageMixin(message, type) {
      this.$message({
        showClose: true,
        message: message || '成功',
        type: type || 'success',
        center: false
      })
    },
    /*
     * loading加载框
     * 调用后通过 this.loadingIdMixin.close() 进行关闭
     * */
    elLoadingMixin() {
      this.loadingIdMixin = this.$loading({
        lock: true,
        text: '数据载入中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      })
    },
    /*
     * 提示
     * message: 提示内容
     * type：提示类型
     * title：提示标题
     * duration：提示时长（ms）
     * */
    elNotifyMixin(message, type, title, duration) {
      type = type || 'success'
      this.$notify[type]({
        title: title || '提示',
        message: message || '请传入提示消息',
        position: 'top-right',
        duration: duration || 2500,
        offset: 40
      })
    },
    /*
      确认弹框(没有取消按钮)
    * title:提示的标题
    * message:提示的内容
    * return Promise
    * */
    elConfirmNoCancelBtnMixin(title, message) {
      return this.$confirm(message || '你确定要删除吗', title || '确认框', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: false,
        type: 'warning'
      }).catch(() => {})
    },
    /*
     * 确认弹框
     * title:提示的标题
     * message:提示的内容
     * return Promise
     * */
    elConfirmMixin(title, message) {
      return this.$confirm(message || '你确定要删除吗', title || '确认框', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
    }
  }
}

export default mixin
