const fleetData = {
  state: {
    tripMapTruck: null,
    enegerTruck: null,
    // fleetOperationFleet: null,//查看车队跳转车队运营时要传的车队数据
    selectFleetTrip: {}, // 选中车队及时间
  },
  mutations: {

    // setFleetOperationData(state,data) {
    //  state.fleetOperationFleet=data;
    // },
    setTripMapTruck(state, data) {
      console.log(" setTripMapTruck data === ", data);
      state.tripMapTruck = data;
    },
    setEnegerTruck(state, data) {
      state.enegerTruck = data;
    },
    setEnegerTruck(state, data) {
      state.enegerTruck = data;
    },
    setSelectFleetTrip(state,data) {
      state.selectFleetTrip = { ...state.selectFleetTrip, ...data };
    }
  },
  getters: {
    // tripMapTruck2 (state) {
    //   //return state.todos.filter(todo => todo.done)
    //   return state.tripMapTruck;
    // }
  },
  actions: {
    setTripMapTruck(context, data) {
      console.log(" setTripMapTruck data -------- ", data);
      context.commit('setTripMapTruck', data)
    }
    ,
    setEnegerTruck(context, data) {
      console.log(" setEnegerTruck data -------- ", data);
      context.commit('setEnegerTruck', data)
    }
    ,
    setSelectFleetTrip(context, data) {
      console.log(" setSelectFleetTrip data -------- ", data);
      context.commit('setSelectFleetTrip', data)
    }
  }
}

export default fleetData