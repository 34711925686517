/*
 * @Author: 谢卓勋 xiezx7@sany.com.cn
 * @Date: 2022-06-20 10:01:23
 * @LastEditors: 谢卓勋 xiezx7@sany.com.cn
 * @LastEditTime: 2022-07-18 15:58:22
 * @Description: 权限管理相关的接口
 */
const serverPrefix = import.meta.env.VITE_APP_NODE_ENV == 'development' ? '/ApiPrefix' : '';
// console.log(import.meta.env.VITE_APP_NODE_ENV)
// console.log(process.env.NODE_ENV)


// 获取模块列表
export function getCategorysReq(username) {
  return axiosReq({
    url: `${serverPrefix}/tenant/v1/org/categorys`,
    method: 'get'
  });
}

// 获取企业列表
export function getEnterpriseListReq(data) {
  return axiosReq({
    url: `${serverPrefix}/tenant/v1/org`,
    method: 'get',
    isParams: true,
    data
  });
}

// 获取员工列表
export function getEmployeeListReq(data) {
  return axiosReq({
    url: `${serverPrefix}/api/org/v1/employee`,
    method: 'get',
    isParams: true,
    data
  });
}

// 获取终端列表
export function getTerminalListReq(data) {
  return axiosReq({
    url: `${serverPrefix}/tenant/v1/terminal`,
    method: 'get',
    isParams: true,
    data
  });
}

// 获取预制角色列表
export function getPresetGroupListReq(data) {
  return axiosReq({
    url: `${serverPrefix}/tenant/v1/preset/group`,
    method: 'get',
    isParams: true,
    data
  });
}

// 获取角色列表
export function getRoleGroupListReq(data) {
  return axiosReq({
    url: `${serverPrefix}/api/org/v1/group`,
    method: 'get',
    isParams: true,
    data
  });
}

// 获取角色列表
export function queryEmployeeByGroupId(data) {
  return axiosReq({
    url: `${serverPrefix}/api/org/v1/employee/groups`,
    method: 'get',
    isParams: true,
    data
  });
}


export function relieveEmployeeReq(data) {
  return axiosReq({
    url: `${serverPrefix}/api/auth/uaa/user/admin/oauth/clear/accountLocked/${data.accountId}`,
    method: 'get',
    isParams: true,
    data
  });
}
// 新增员工信息
export function addEmployeeReq(data) {
  return axiosReq({
    url: `${serverPrefix}/api/org/v1/employee`,
    method: 'post',
    data
  });
}
// 更新员工信息
export function updateEmployeeInfo(data) {
  return axiosReq({
    url: `${serverPrefix}/api/org/v1/employee/${data.id}`,
    method: 'put',
    data
  });
}

// 角色组中新增员工
export function addEmployeeToGroupReq(data) {
  return axiosReq({
    url: `${serverPrefix}/api/org/v1/employee/set/group/${data.groupId}`,
    method: 'put',
    data
  });
}

// 角色组中移除员工
export function deleteEmployeeToGroupReq(data) {
  return axiosReq({
    url: `${serverPrefix}/api/org/v1/employee/set/group/remove/${data.groupId}`,
    method: 'put',
    data
  });
}