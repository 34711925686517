/*
 * @Author: 谢卓勋 xiezx7@sany.com.cn
 * @Date: 2022-06-17 10:21:00
 * @LastEditors: 谢卓勋 xiezx7@sany.com.cn
 * @LastEditTime: 2022-06-20 17:24:43
 * @Description: 动态路由
 */
import Layout from '@/framework/layout';
export default [
  {
    code: 'iov:systemMange',
    path: '/systemMange',
    name: 'systemMange',
    alwaysShow: true,
    component: Layout,
    redirect: 'employeeManager',
    meta: { title: '系统管理', elSvgIcon: 'Fold' },
    children: [
      {
        code: 'iov:employeeManager',
        path: '/employeeManager',
        name: 'employeeManager',
        component: () => import('@/framework/views/employeeManager/index.vue'),
        meta: { title: '员工管理', cachePage: true, leaveRmCachePage: false }
      },
      {
        code: 'iov:employeeDetail',
        path: '/employeeDetail',
        name: 'employeeDetail',
        component: () => import('@/framework/views/employeeManager/Detail.vue'),
        hidden: true,
        meta: { title: '员工详情', cachePage: true }
      },
      {
        code: 'iov:employeeAuthManager',
        path: '/employeeAuthManager',
        name: 'employeeAuthManager',
        component: () => import('@/framework/views/employeeAuthManager/index.vue'),
        meta: { title: '员工权限', cachePage: true }
      },
    ]
  }
];
